<template>
    <el-form>
        <div class="d-flex flex-column flex-xl-row" v-if="settings">
            <p class="border-end border-2 py-4 my-xl-5 mb-0 text-gray-600 fs-4 fw-bold w-xl-25">
                <inline-svg class="me-4" src="/media/buying/icons/personal-information.svg" />
                {{ $t("Personal Information") }}
            </p>
            <div class="ms-xl-10 w-xl-75 my-3">
                <div
                    class="my-4 d-flex justify-content-between align-items-center w-100"
                    v-for="(item, key) in settings"
                    :class="{'is-disabled': !item.activeForEditing}"
                >
                    <label class="fw-bold fs-4 mb-0">
                        {{ $t("user_" + key) }}
                        <img v-if="!item.activeForEditing" src="/media/misc/info_black.svg" alt="">
                    </label>
                    <el-form-item class="mb-0">
                        <el-switch
                            v-model="item.enabled"
                            :disabled="!item.activeForEditing"
                            :loading="lItems.has(key)"
                            @change="handleChange($event ,key)"
                        />
                    </el-form-item>
                </div>
            </div>
        </div>
    </el-form>
</template>
<script>
import PolicySharing from "@/buying-teams/shared-components/settings/PolicySharing";
import store from "@/store";

export default {
    name: "SharingPolicy",
    props: {
        sharingSettings: {
            type: Object
        }
    },
    components: {
        PolicySharing
    },
    data() {
        return {
            delivery: "",
            settings: null,
            lItems: new Set()
        };
    },
    computed: {
        business() {
            return store.getters.business;
        },
        currentUser() {
            return store.getters.currentUser;
        }
    },
    created() {
        this.settings = {};
        for (let [key, item] of Object.entries(this.sharingSettings)) {
            this.settings[key] = {
                enabled: !!item.enabled,
                activeForEditing: this.currentUser.isAdmin ? true : this.currentUser.can(key)
            };
        }
    },
    methods: {
        updateSettings() {
            const currentUserSettings = { ...this.currentUser.settings };
            for (let [key, item] of Object.entries(this.settings)) {
                currentUserSettings.sharing[key].enabled = item.enabled ? 1 : 0;
            }
            this.currentUser.updateSettings(currentUserSettings);
        },
        handleChange(event, key) {
            this.addToLoading(key);
            store.dispatch("updateUserSettings", {
                "section": "sharing",
                "payload": {
                    [key]: {
                        "enabled": event ? 1 : 0
                    }
                }
            }).then(() => {
                this.updateSettings();
            }).finally(() => {
                this.addToLoading(key);
            });
        },
        addToLoading(id) {
            if (this.lItems.has(id)) this.lItems.delete(id);
            else this.lItems.add(id);
        }
    }
};
</script>
<style lang="scss" scoped>
.sheringInfo {
    background: #E1E5FF;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5561AA;
    border-radius: 34px;
    margin-bottom: 25px;
}

.is-disabled {
    label {
        color: #040404;
        opacity: 0.6;
    }
}
</style>
