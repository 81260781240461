<template>
    <el-form>
      <div v-if="type === 'business'" class="d-flex">
        <p class="border-end border-2 py-4 my-5 text-gray-600 fs-4 fw-bold w-25">
          <inline-svg class="me-4" src="/media/buying/icons/invitations.svg" />
          {{ $t('Invitations') }}
        </p>
        <div class="ms-10 w-75 my-3">
          <div class="my-4 d-flex justify-content-between align-items-center w-100">
            <label class="fw-bold fs-4 mb-0">{{ $t('Allow users to invite other colleagues to CXFacts') }}</label>
            <el-form-item class="mb-0">
              <el-switch v-model="delivery" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div v-if="type === 'business'" class="d-flex">
        <p class="border-end border-2 py-4 my-5 text-gray-600 fs-4 fw-bold w-25">
          <inline-svg class="me-4" src="/media/buying/icons/function-access.svg" />
          {{ $t('Function Access') }}
        </p>
        <div class="ms-10 w-75 my-3">
          <div class="my-4 d-flex justify-content-between align-items-center w-100">
            <label class="fw-bold fs-4 mb-0">{{ $t('Allow users to start feedback sessions') }}</label>
            <el-form-item class="mb-0">
              <el-switch v-model="delivery" />
            </el-form-item>
          </div>
          <div class="my-4 d-flex justify-content-between align-items-center w-100">
            <label class="fw-bold fs-4 mb-0">{{ $t('Allow users to share requests with bank') }}</label>
            <el-form-item class="mb-0">
              <el-switch v-model="delivery" />
            </el-form-item>
          </div>
          <div class="my-4 d-flex justify-content-between align-items-center w-100">
            <label class="fw-bold fs-4 mb-0">{{ $t('Allow users to share ideas with bank') }}</label>
            <el-form-item class="mb-0">
              <el-switch v-model="delivery" />
            </el-form-item>
          </div>
          <div class="my-4 d-flex justify-content-between align-items-center w-100">
            <label class="fw-bold fs-4 mb-0">{{ $t('Allow users to share comments from feedback sessions with bank') }}</label>
            <el-form-item class="mb-0">
              <el-switch v-model="delivery" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <p class="border-end border-2 py-4 my-5 text-gray-600 fs-4 fw-bold w-25">
          <inline-svg class="me-4" src="/media/buying/icons/personal-information.svg" />
          {{ $t('Personal Information') }}
        </p>
        <div class="ms-10 w-75 my-3">
          <div class="my-4 d-flex justify-content-between align-items-center w-100">
            <label class="fw-bold fs-4 mb-0">{{ $t('Allow users to share full name with bank') }}</label>
            <el-form-item class="mb-0">
              <el-switch v-model="delivery" />
            </el-form-item>
          </div>
          <div class="my-4 d-flex justify-content-between align-items-center w-100">
            <label class="fw-bold fs-4 mb-0">{{ $t('Allow users to share job title with bank') }}</label>
            <el-form-item class="mb-0">
              <el-switch v-model="delivery" />
            </el-form-item>
          </div>
          <div class="my-4 d-flex justify-content-between align-items-center w-100">
            <label class="fw-bold fs-4 mb-0">{{ $t('Allow users to share country of residence with bank') }}</label>
            <el-form-item class="mb-0">
              <el-switch v-model="delivery" />
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
</template>

<script>

export default {
  name: "PolicySharing",
  components: {},
  props: ['type'],
  data() {
    return {
      delivery: ""
    };
  }
};
</script>
<style lang="scss">

</style>
