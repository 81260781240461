<template>
    <div v-if="getUserSettingsLoading" class="mx-0">
        <CustomLoader />
    </div>
    <template v-else>
        <div class="sheringInfo" v-if="showInfoText">
            <div class="sheringInfo__icon">
                <inline-svg width="16px" height="16px" src="/media/buying/icons/info_outined.svg" />
            </div>
            <span>
                {{ $t("In case you cannot change these settings, your company admin has restricted sharing. Please contact your company for assistance")}}
            </span>
            <button @click="showInfoText = false">
                <inline-svg width="12px" src="/media/buying/icons/close-red.svg"/>
            </button>
        </div>
        <div class="card pt-3 px-10">
            <div class="card-header cursor-pointer px-0">
                <div class="card-title m-0">
                    <h3 class="fw-bolder m-0">
                        {{ $t("Define your sharing policy") }}
                    </h3>
                </div>
            </div>
            <UserSharingPolicy :sharingSettings="getUserSettingsData" />
        </div>
    </template>
</template>
<script>
import UserSharingPolicy from "@/buying-teams/pages/business/settings/user/UserSharingPolicy";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";

export default {
    name: "SharingPolicy",

    components: {
        CustomLoader,
        UserSharingPolicy
    },

    computed: {
        currentUser() {
            return store.getters.currentUser;
        },
        getUserSettingsData() {
            return store.getters.currentUser.settings.sharing;
        },
        getUserSettingsLoading() {
            return store.getters.getUserSettingsLoading;
        }
    },

    data() {
        return {
            showInfoText: false,
        };
    },

    created() {
        this.showInfoText = !this.currentUser.isAdmin;
    }

};
</script>
<style lang="scss">
.sheringInfo {
    display: flex;
    padding: 12px 24px 12px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 7px;
    border: 1px solid #9AA4E5;
    background: #EDEFFD;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    margin-bottom: 26px;
    &__icon {
        margin-right: 8px;
    }
    span {
        width: calc(100% - 44px);
        text-align: left;
    }
    button {
        width: 20px;
        height: 20px;
        background: transparent;
        border: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        transition: .3s;
        svg path {
            fill: #435BF4;
        }
        &:hover {
            background: #dce0fb;
        }
    }

}
</style>
